<template>
  <section class="info">
       <div class="info__main">
           <h2>Informations</h2>
           <Information v-for="info in infos" :key="info.title" :info="info" :id="info.id" />
       </div>
  </section>
</template>

<script>
import Information from '@/components/Information'

export default {
    name: "Info",
    data() {
        return {
            infos: [
                {
                id:"contact",
                title: 'About Me',
                content: `Hi. I am 23 y.o. producer from Poland. I've started making beats in late secondary school (about 2012). During the period I've gained
                            a lot of useful music production experience, and I've set up my home recording studio. Now I make beats after fulltime job and in weekends.
                            I love to make beat in medieval/folk style because I fell that I am closer to nature. If you want get to know each other better, contact me via
                            messenger or e-mail`,
                },
                {
                id:"mix-master",
                title: 'Mix & Master',
                content: `In addition to producing beats, I also deal with mix mastering of recorded songs. 
                            If you want me to mix your track for you, please contact me.`
                },
                {
                id:"contact",
                title: 'Contact',
                content: `Email: maciejwsrh97@wp.pl`
                },
                {
                id:"how-to-buy",
                title: 'How to buy beat ?',
                content: `If you want to buy a beat through my website you need to have a PayPal account. Choose the beat you want to buy, then choose the license that's right for you. Select the currency in which you wish to make the purchase and click the 'Buy Beat' button. You will be redirected to the paypal page where you will have to log in and make the payment. After purchase, contact me and I will send you a beat that matches the license you have purchased.`
                },
                {
                id:"when-you-recieve-the-beat", 
                title: 'When you recieve the beat?',
                content: `After purchasing the beat, contact me via e-mail: (maciejwsrh97@wp.pl) or messenger. Write which beat you've bought and which license you've choosen. I will send the beat to you within 24 hours (usually a few hours after purchase)`
                },
                {
                id:"how-i-transfer-copyright-to-buyer",    
                title: 'How I transfer copyright to buyer?',
                content: `To transfer the copyright to the buyer, I will prepare a special form. This step can be skipped if the buyer does not wish to do so. In both cases, the buyer can use the beat with the limits specified in the license.`
                },
                {
                id:"how-can-you-do-with-buyed-track",    
                title: 'How can you do with buyed track?',
                content: `The buyer can use the beat under the terms of the license that he chooses when buying the beat.`
                },
                {
                id:"can-you-pay-differently-than-by-paypal",    
                title: 'Can you pay differently than by paypal ?',
                content: `If you want to pay for the beat other than paypal, please contact me via messeneger or email (maciejwsrh97@wp.p).`
                },
            ]
        }
    },
    components: {
        Information
    }
}
</script>

<style lang="scss" scoped>
.info {
    background-color: $lightGrey;


    &::before {
        content: '';
        position: absolute;
        top: 58px;
        left: 0;
        width: 100vw;
        height: 32vh;
        background: linear-gradient(0deg, $lightGrey 10%, $black 70%);
        z-index: -1;
    }

    &__main {
        position: relative;
        width: 86%;
        margin: 0 auto;
        height: 100%;
        padding: 10vh 12vw;

        @include mq($to: tablet) {
            padding: 10vh 6vw;
        }

        @include mq($to: mobile) {
            padding: 10vh 0;
        }



        & h2 {
            @include fontEraox;
            font-size: 42px;
            letter-spacing: 2px;
            color: $almound;
            text-align: center;

            @include mq($to: mobile) {
                font-size: 8vw;
            }
        }
    }
}

</style>