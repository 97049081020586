<template>
  <section class="information">
    <h3>{{info.title}}</h3>
    <p>{{info.content}}</p>
  </section>
</template>

<script>

export default {
    name: "Information",
    props: {
        info: {
            type: Object
        }
    }
}
</script>

<style lang="scss" scoped>
.information {
    @include fontPoppins;
    color: $almound;
    margin-top: 40px;

    & h3 {
        font-size: 32px;
        text-align: center;
    }

    & p {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }
}

</style>